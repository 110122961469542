/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */



import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import './src/styles/main.css';
import Layout from './src/components/layout'

// const wrapPageElement = ({ element, props }) => (
//   <Layout {...props}>{element}</Layout>
// )

const wrapRootElement = ({ element }) => <MDXProvider>{element}</MDXProvider>

export { wrapRootElement }