import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Location, useLocation } from '@reach/router'

const navLinks = [
  {
    name: 'Home',
    link: '/'
  },
  {
    name: 'Blog',
    link: '/blog'
  },
]

const Header = ({ siteTitle, transparentHeader }) => {
  const { pathname } = useLocation();
  const isTransparent = transparentHeader ? transparentHeader : false;

  return (
    <header className={`${isTransparent ? 'absolute z-20' : 'bg-white border-b-2 border-gray-200/70'} w-full`}>
      <div className="container mx-auto py-6 px-6">

        <div className="flex items-center justify-between">
          <Link to="/">
            <StaticImage
              className="hidden md:block"
              placeholder="none"
              layout="fixed"
              src="../images/logo.png"
              height={42}
              formats={["AUTO", "WEBP"]}
              alt="Logo"
            />
            <img src="/images/logo-sm.png" className="md:hidden h-11" />
          </Link>

          <div>
            <ul className="flex items-center">
              {/* {navLinks.map((nav) => (
                <li className={`${nav.link === pathname ? 'text-blue-600' : 'text-gray-800 hover:text-gray-600'} transition duration-150 text-lg font-semibold ml-10`}><Link to={nav.link}>{nav.name}</Link></li>
              ))} */}
              <li className="ml-10">
                <a href="mailto:hello@editsquare.com" className="inline-flex items-center justify-center py-2 px-4 rounded-md text-lg font-semibold text-white bg-gradient-to-tl from-sky-500 via-blue-600 to-indigo-500 hover:from-blue-600 hover:to-blue-600">Get in touch</a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
