/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Link } from "gatsby";
import { ParallaxProvider } from 'react-scroll-parallax';
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"

const Layout = ({ children, transparentHeader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  return (
    <ParallaxProvider>
      <div className="h-full flex flex-col">
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} transparentHeader={transparentHeader} />
        <main className="">{children}</main>
        <footer className="bg-black text-gray-500 py-5">
          <div className="container mx-auto px-6">
            <div className="flex flex-wrap">
              <div className="w-full sm:w-1/2">© Edit Square LTD {new Date().getFullYear()}</div>
              <div className="w-full sm:w-1/2 sm:text-right"><Link className="pr-3 border-r border-gray-600 transition duration-100 hover:text-gray-300" to="/privacy-policy">Privacy Policy</Link><Link className="pl-3 transition duration-100 hover:text-gray-300" to="/terms">Terms of use</Link></div>
            </div>
          </div>
        </footer>
      </div>
    </ParallaxProvider>
  )
}

export default Layout
